import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import "@/assets/css/root.css";
import "@/assets/css/main.css";
import "@/assets/css/header.css";
import "@/assets/css/bugger.css";
// import "@/assets/css/styles-old.css";

createApp(App).mount('#app')
