<template>
  <Header />
  <section class="what-we-do-hero section-offset" :style="backgroundStyle">
    <div class="container">
      <div class="digital-clock"><div>{{ clockTime }}</div></div>
        <div class="container">
          <div class="breadcrumbs">
            <li>{{ currentDate }}</li> 
          </div>
          <h2 class="what-we-do-hero__text h2"> Jesteśmy firmą specjalizującą się w tworzeniu nowoczesnych rozwiązań software'owych.</h2>
            <div class="what-we-do-hero__body">
            </div> 
        </div>
    </div>
  </section>

     
  <section  class="why-new section-offset">
    <div  class="container">
      <div  class="why-new__list">
        <div  class="why-new__item " >
          <a class="industry-card industry-card_flip" href="#">
            <div class="industry-card__front ">
              <div class="industry-card__preview">
                <img class="" :src="require('@/assets/img/47.jpg')" >
              </div>
              <div class="industry-card__flip-container">
                <div class="industry-card__face ">
                  <h3 class="industry-card__title ">Rozwój oprogramowania</h3>
                </div>
              </div>
            </div>
            <div class="industry-card__back ">
              <div class="industry-card__flip-container">          
                <h3 class="industry-card__title ">Rozwój oprogramowania</h3>
                <p class="industry-card__text body-2">
                  Tworzymy aplikacje dostosowane do Twoich potrzeb, wykorzystując najnowsze technologie i najlepsze praktyki
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="why-new__item" id="mobile">
          <a class="industry-card industry-card_flip" href="#mobile">
            <div class="industry-card__front ">
              <div class="industry-card__preview">
                <img class="" :src="require('@/assets/img/mobile.jpg')" >
              </div>
              <div class="industry-card__flip-container">
                <div class="industry-card__face ">
                  <h3 class="industry-card__title ">Aplikacje mobilne</h3>
                </div>
              </div>
            </div>
            <div class="industry-card__back ">
              <div class="industry-card__flip-container">      
                <h3 class="industry-card__title ">Aplikacje mobilne</h3>
                  <p class="industry-card__text body-2">
                    Projektujemy i rozwijamy aplikacje mobilne na systemy iOS i Android, które angażują użytkowników.
                  </p>
              </div>
            </div>
          </a>
        </div>
        <div class="why-new__item" id="crm">
          <a class="industry-card industry-card_flip" href="#crm">
            <div class="industry-card__front ">
              <div class="industry-card__preview">
                <img class="" :src="require('@/assets/img/25561305_7090309.jpg')" >
              </div>
              <div class="industry-card__flip-container">
                <div class="industry-card__face ">
                  <h3 class="industry-card__title ">CRM - Client Connect</h3>
                </div>
              </div>
            </div>
            <div class="industry-card__back ">
              <div class="industry-card__flip-container">      
                <h3 class="industry-card__title ">CRM - Client Connect</h3>
                  <p class="industry-card__text body-2">
                    Client Connect to kompleksowe rozwiązanie CRM, zaprojektowane do zarządzania wszystkimi aspektami relacji z klientami. Oferuje narzędzia do zarządzania kontaktami, śledzenia interakcji oraz automatyzacji działań sprzedażowych i marketingowych.
                  </p>
              </div>
            </div>
          </a>
        </div>
        <div class="why-new__item" id="cms">
          <a class="industry-card industry-card_flip" href="#cms">
            <div class="industry-card__front ">
              <div class="industry-card__preview">
                <img class="" :src="require('@/assets/img/cms.jpg')" >
              </div>
              <div class="industry-card__flip-container">
                <div class="industry-card__face ">
                  <h3 class="industry-card__title ">CMS - Content Master</h3>
                </div>
              </div>
            </div>
            <div class="industry-card__back ">
              <div class="industry-card__flip-container">      
                <h3 class="industry-card__title ">CMS - Content Master</h3>
                  <p class="industry-card__text body-2">
                    Content Master to zaawansowany system zarządzania treścią, który umożliwia łatwe tworzenie, edycję i publikowanie zawartości na stronach internetowych. Zaprojektowany z myślą o elastyczności i skalowalności, wspiera zarówno małe, jak i duże przedsiębiorstwa w efektywnym dostarczaniu treści cyfrowych.
                  </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>

  <section class="bg-black text-white py-5">
    <div class="container">
      <h2 class="text-center mb-5">Skontaktuj się z nami</h2>
      <div class="row">
        <div class="col-md-6">
          <p><strong>Adres:</strong> ul. Gminna 36G, 05-506 Lesznowola</p>
          <p><strong>Email:</strong> <a href="mailto:tk@tkservices.pl" class="text-white">tk@tkservices.pl</a></p>
          <p><strong>Telefon:</strong> +48 793 133 224</p>
        </div>
      </div>
    </div>
  </section>
  <footer class="text-center py-3">
    <p class="mb-0">&copy; 2025 TK Services.</p>
  </footer>
  
</template>

<script>
import Header from './Header.vue';
export default {
  name: "MainComponent",
  components: {
    Header
  },
  data() {
    return {
      imageUrl: require("@/assets/img/bg.jpg"), //'/src/assets/hero_image.jpg'
      clockTime: this.getCurrentTime(), // Initialize clock
      currentDate: this.getCurrentDate() // Initialize date
    };
  },
  created() {
    setInterval(() => {
      this.clockTime = this.getCurrentTime(); // Update clock every second
      this.currentDate = this.getCurrentDate(); // Update date every second
    }, 1000);
  },
  methods: {
    scrollTo(refName) {
      const element = this.$refs[refName];
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    getCurrentTime() {
      const now = new Date();
      return now.toTimeString().substring(0, 8); // Return time as HH:MM:SS
    },
    getCurrentDate() {
      const now = new Date();
      return now.toLocaleDateString('pl-PL', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }); // Format the date in a friendly manner
    }
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: `url(${this.imageUrl})`
      };
    }
  }
};
</script>

<style scoped>
.breadcrumbs {
    list-style: none;
    padding: 0;
}
.breadcrumbs li {
    display: inline;
    margin-right: 10px;
    opacity: 0.6;
}
.breadcrumbs a {
    text-decoration: none;
    color: inherit;
}

.digital-clock {
  font-size: 2rem; /* Size of the clock */
  color: white; /* Clock text color */
  padding: 20px; /* Spacing around the text */
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background for better visibility */
  width: 200px; /* Width of the clock container */
  text-align: center; /* Center the clock text */
  border-radius: 10px; /* Rounded corners for aesthetics */
  position: absolute; /* Positioning relative to the section */
  top: 20px; /* Distance from the top */
  right: 20px; /* Distance from the right */
  z-index: 1001; /* Ensure it's above other elements */
}

.why-new {
    --list-gutter-y: 16px;
    --list-offset: 40px;
    --item-height: 108px;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

@media (min-width: 768px) {
    .why-new {
        --list-gutter-y: 24px;
        --list-offset: 48px;
        --item-height: 168px;
    }
}

@media (min-width: 1000px) {
    .why-new {
        --item-height: 221px;
    }
}

@media (min-width: 1310px) {
    .why-new {
        --item-height: 414px;
    }
}

@media (min-width: 1700px) {
    .why-new {
        --list-gutter-y: 32px;
        --item-height: 414px;
    }
}

.why-new__list {
    display: grid;
    gap: var(--list-gutter-y) var(--gutter-x);
    margin-top: var(--list-offset);
}

@media (min-width: 1310px) {
    .why-new__list {
        grid-template-columns: repeat(2, 1fr);
    }
}

.why-new__item {
    min-height: var(--item-height);
}

.why-new__item:nth-of-type(-n+2) {
    --item-height: 186px;
}

@media (min-width: 768px) and (max-width: 999.98px) {
    .why-new__item:nth-of-type(-n+2) {
        --item-height: 400px;
    }
}

@media (min-width: 1000px) and (max-width: 1309.98px) {
    .why-new__item:nth-of-type(-n+2) {
        --item-height: 414px;
    }
}

@media (min-width: 1310px) {
    .why-new__item:nth-of-type(1) {
        --item-height: 414px;
    }
}

@media (min-width: 1700px) {
    .why-new__item:nth-of-type(1) {
        --item-height: 414px;
    }
}


.industry-card {
    --height: 108px;
    --title-mt: 0;
    --title-fz: 18px;
    --title-lh: 24px;
    --card-p-x: 24px;
    --card-p-y: var(--card-p-x);
    --frontDeg: 0deg;
    --backDeg: 180deg;
    position: relative;
    display: block;
    color: #fffffa;
    height: 100%;
    min-height: var(--height);
}

@media (min-width: 768px) {
    .industry-card {
        --title-fz: 30px;
        --title-lh: 40px;
        --card-p-x: 32px;
    }
}

@media (min-width: 1500px) {
    .industry-card {
        --card-p-x: 40px;
    }
}

.industry-card:hover {
    color: #fffffa;
}

.industry-card:hover:not(.industry-card_flip) .industry-card__preview img {
    transform: scale(1.05);
}

.industry-card__preview {
    position: absolute;
    inset: 0;
    overflow: hidden;
    z-index: -1;
}

.industry-card__preview img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: transform var(--transition-custom);
}

.industry-card__face {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding: var(--card-p-y) var(--card-p-x);
}

.industry-card__title {
    font-size: var(--title-fz);
    line-height: var(--title-lh);
    margin-top: var(--title-mt);
}

.industry-card__text {
    margin-top: 32px;
}

.industry-card__tags {
    list-style-type: none;
    padding: 0;
    margin: auto 0 0;
    width: 467px;
    max-width: 100%;
    font-size: 14px;
    line-height: 20px;
}

.industry-card__tag {
    display: inline;
}


.industry-card__front, .industry-card__back {
    height: 100%;
    transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
    backface-visibility: hidden;
}

.industry-card__front {
    position: absolute;
    inset: 0;
    transform: rotateX(var(--frontDeg));
    transform-style: preserve-3d;
}

.industry-card__back {
    position: relative;
    background-color: #2442ab;
    transform: rotateX(var(--backDeg));
    transform-style: preserve-3d;
    padding: var(--card-p-y) var(--card-p-x);
}

.industry-card__back .industry-card__flip-container {
    display: flex;
    flex-direction: column;
}

.industry-card__flip-container {
    height: 100%;
    transform: translateZ(60px) scale(0.98);
}

.industry-card_flip {
    transform-style: preserve-3d;
    perspective: 3000px;
}

@media (min-width: 1310px) {
    .industry-card_flip {
        --card-p-x: 40px;
        --title-mt: 8px;
    }
}

.industry-card_flip:hover {
    --frontDeg: -180deg;
    --backDeg: 0deg;
}

/* ------ */


.what-we-do-hero {
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    }
.container {
    width: 100%;
    /* max-width: 1200px; */
    margin: 0 auto;
    padding: 20px;
}
.breadcrumbs {
    list-style: none;
    padding: 0;
}
.breadcrumbs li {
    display: inline;
    margin-right: 10px;
    opacity: 0.6;
}
.breadcrumbs a {
    text-decoration: none;
    color: inherit;
}
.page-scroll__button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.page-scroll__icon {
    margin-right: 5px;
}
.section-why-five-dim{position:relative}@media (min-width: 1310px){.section-why-five-dim__title{max-width:6em}}

/* -------------------- */
.divider {
    position: relative;
    width: 100%;
    height: var(--divider-h);
    background: #fff;
    clip-path: polygon(0 100%, 50% 100%, 50% 50%, 100% 50%, 100% 0, 50% 0, 50% 50%, 0% 50%);
}
.divider_top {
    position: absolute;
    inset: 0;
    translate: 0 -50%; /* Przesunięcie w dół o 50% wysokości elementu */
}
/* ------------------ */

.card-simple_mod-2 {
    /* --bg-img: url('@/assets/images/backgrounds/card_bg_orange.svg'); */
    position: relative;
    padding: 24px;
    margin-top: 4px;
    background: var(--bg-img) center / cover no-repeat;
}

@media (min-width: 768px) {
  .card-simple_mod-2 {
    padding: 40px;
    margin-top: 6px;
  }
}

@media (min-width: 1000px) {
  .card-simple_mod-2 {
    padding-bottom: 36px;
  }
}

@media (min-width: 1700px) {
  .card-simple_mod-2 {
    padding-bottom: 58px;
  }
}

.card-simple_mod-2 .card-simple__count {
  font-size: 12px;
  letter-spacing: 1px;
}

@media (min-width: 768px) {
  .card-simple_mod-2 .card-simple__count {
    line-height: 24px;
    opacity: .7;
  }
}

.card-simple_mod-2 .card-simple__title {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-top: 4px;
}

@media (min-width: 768px) {
  .card-simple_mod-2 .card-simple__title {
    font-size: 30px;
    line-height: 40px;
    min-height: var(--element-height);
  }
}

@media (min-width: 1700px) {
  .card-simple_mod-2 .card-simple__title {
    font-size: 34px;
    line-height: 44px;
  }
}

.card-simple_mod-2 .card-simple__text {
  margin-top: 16px;
}

.card-post{--body-color: #020412;--body-bg: #FFFFFA;--body-pt: 24px;--body-p: 24px;--img-h: 198px;--offset-h3: 8px;position:relative;z-index:1}@media (min-width: 768px){.card-post{--body-pt: 32px;--body-p: 40px;--img-h: 214px;--offset-h3: 16px;height:100%;display:flex;flex-direction:column}}@media (min-width: 1000px){.card-post{--img-h: 238px}}.card-post[href]:hover{--body-color: #FFFFFA;--body-bg: #2442AB}.card-post__image{display:block;height:var(--img-h)}.card-post__body{color:var(--body-color);background-color:var(--body-bg);padding:var(--body-pt) var(--body-p) var(--body-p) var(--body-p);transition:color .2s ease-in-out,background-color .2s ease-in-out}@media (min-width: 768px){.card-post__body{flex-grow:1}}@media (max-width: 767.98px){.card-post__text{font-size:16px}}.blurb-sma{--blur-pb: 28px 32px;--counter-t: 8px;position:relative;padding-block:var(--blur-pb);height:100%}@media (min-width: 768px){.blurb-sma{--blur-pb: 38px 48px;--counter-t: 16px;--h3-fz: 30px;--h3-lh: 40px;--offset-h3: 40px}}@media (min-width: 1700px){.blurb-sma{--h3-fz: 34px;--h3-lh: 44px}}.blurb-sma__counter{position:absolute;top:var(--counter-t);left:0;font-weight:700}.blurb-sma__image{margin-left:16px}.blurb-sma__title{max-width:8.5em}

</style>
