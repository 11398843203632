<template>
  <Main msg="Welcome to Vue.js App"/>
</template>

<script>
import Main from './components/Main.vue'

export default {
  name: 'App',
  components: {
    Main
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: aliceblue;
  overflow-x: hidden;
  height: 100vh;
  /* margin: 0; */
  background: linear-gradient(to right, #000000 0%, #00000280 40%);
}
</style>
