<template>
<header :class="['header', { 'header--scrolled': isScrolled }]">
    <div class="header__container container">
        <div class="header__body">
            <div class="header__logo">
              <a href="#" aria-label="">        
              TKSERVICES.PL
          </a>
            </div>
            <div class="header__nav" :class="{ 'header__nav--open': isMenuOpen || isDesktop }">
                <nav id="nav" class="nav counter-list">
                      
                    
                    <div class="nav__item">
                        <a class="nav__link" href="#crm">
                            <span class="nav__link-text">Client Connect</span>
                        </a>
                    </div>
                    <div class="nav__item">
                        <a class="nav__link" href="#cms">
                            <span class="nav__link-text">Content Master</span>
                        </a>
                    </div>
                    <div class="nav__item">
                        <a class="nav__link" href="#mobile">
                            <span class="nav__link-text">Mobile</span>
                        </a>
                    </div>
                </nav>
            </div>
            <div class="header__button">
                
            </div>
            
            <!-- <div class="language-switch">
              <span class="language-option" :class="{ active: !isEnglish }" @click="changeLanguage('pl')">PL</span>
              <span class="language-option" :class="{ active: isEnglish }" @click="changeLanguage('en')">EN</span>
            </div> -->
            <div class="header__toggle">
                <button aria-label="toggle sidebar" class="bugger" @click="toggleMenu">
                    <span></span>
                </button>
            </div>
        </div>
    </div>
</header>
</template>

<script>
export default {
  name: "HeaderComponent",
  data() {
    return {
      isMenuOpen: false,
      isDropdownOpen: false,
      isDropdownOpen1: false,
      isDesktop: window.innerWidth > 1308,
      isScrolled: false,
      // isEnglish: this.$i18n.locale === 'en'
    };
  },
  watch: {
    // '$i18n.locale'(newLocale) {
    //   this.isEnglish = newLocale === 'en';
    // }
  },
  methods: {
    getImageSrc() {
      // return require(`@/assets/images/${'5D-logo_white.png'}`);
    },
    changeLanguage() {
      // if (this.$i18n.locale !== lang) {
      //   this.$i18n.locale = lang;
      //   const currentPath = this.$route.path;
      //   let newPath = currentPath.replace(/^\/(pl|en)/, '');
      //   newPath = `/${lang}${newPath}`;
      //   this.$router.push(newPath);
      // }
    },
    toggleLanguage() {
      // const newLang = this.isEnglish ? 'pl' : 'en';
      // this.changeLanguage(newLang);
    },
    getLocalizedLink() {
    // const match = this.$route.path.match(/^\/(pl|en)/);
    // const lang = match ? match[1] : this.$i18n.locale;
    // this.$i18n.locale = lang; // Update the locale based on URL before generating link
    // return `/${lang}${path}`;
  },
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;

    // Dodajemy lub usuwamy klasę blokującą przewijanie tła
    if (this.isMenuOpen) {
      document.body.style.overflow = "hidden"; // Blokada przewijania
    } else {
      document.body.style.overflow = ""; // Przywracanie przewijania
    }
  },
    toggleDropdown() {
      if (!this.isDesktop) {
        this.isDropdownOpen = !this.isDropdownOpen;
      }
    },
    handleResize() {
      this.isDesktop = window.innerWidth > 1309;
      if (this.isDesktop) {
        this.isMenuOpen = false;
        this.isDropdownOpen = false;
      }
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 10;
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);
    // const currentPath = this.$route.path;
    // const match = currentPath.match(/^\/(pl|en)/);
    // if (match) {
      // this.$i18n.locale = match[1];
      // this.isEnglish = match[1] === 'en';
    // }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped>

.btn-contact {
    display: flex;
    align-items: center;
    gap: 8px; /* Adjust spacing between icon and text if needed */
}

.contact-icon {
    width: 24px; /* Set your desired icon size */
    height: 24px;
}

.contact-text {
    display: block; /* Ensure text is always visible by default */
}

@media (min-width: 769px) {
    .contact-icon {
        display: none; /* Show text on larger screens */
    }
}

@media (max-width: 768px) { /* Adjust breakpoint as necessary */
    .contact-text {
        display: none; /* Hide text on smaller screens */
    }
    .btn-contact {
        justify-content: center; /* Center the icon when text is hidden */
    }
}


.language-switch {
  margin-left: 10px;
}
.header__nav {
  display: none;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  padding: 20px;
  z-index: 100;
}
.header__nav--open {
  display: block;
  transform: translateX(0);
}
.header__toggle {
  display: none;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 110;
}
.burger {
font-size: 28px;
color: #fff;
background: transparent;
border: none;
cursor: pointer;
z-index: 150;
transition: color 0.3s ease-in-out;
}

.nav__item-has-dropdown {
  cursor: pointer;
}
.dropdown-arrow {
  margin-left: 8px;
  font-size: 14px;
}

@media (min-width: 769px) {
  .header__nav {
    display: flex;
    position: static;
    transform: none;
    align-items: center;
    gap: 16px;
    width: auto;
    height: auto;
    background-color: transparent;
    padding: 0;
  }
}

@media (max-width: 1309px) {
  .header__toggle {
    display: block;
  }
  .nav__item {
    margin-bottom: 16px;
  }
  .language-switch {
    margin-right: 45px;
  }
  }
  .header--scrolled {
  --header-bgc: var(--bg-dark);
  --line-opacity: 0; /* Hide the bottom line */
  background-color: var(--bg-dark);
  transition: background-color 0.3s ease-in-out;
  }
  .language-label {
  font-size: 16px;
  color: #000;
  }
  .language-option {
  font-size: 14px;
  cursor: pointer;
  padding: 5px 5px;
  border-radius: 2px;
  transition: background-color 0.3s;
  }
  .language-option.active {
  background-color: #2196F3;
  color: #fff;
  }
  .language-option:hover {
  background-color: #e0e0e0;
  }
/* Styl głównego kontenera */
.header__body {
display: flex;
align-items: center;
justify-content: space-between; /* Kluczowe wyrównanie do prawej */
width: 100%;
}

/* Logo po lewej */
.header__logo {
flex-shrink: 0;
}

/* Nawigacja na środku */
.header__nav {
display: flex;
flex-grow: 1; /* Rozciąga nawigację */
margin-left: 25%;
gap: 20px;
}

/* Kontakt i przełącznik języka po prawej */
.header__right {
display: flex;
align-items: center;
gap: 20px;
margin-left: auto; /* Przesuwa do prawej */
}


.language-option {
font-size: 16px;
cursor: pointer;
padding: 5px 5px;
border-radius: 2px;
transition: background-color 0.3s, color 0.3s;
}

.language-option.active {
background: linear-gradient(90deg, #f39821, #ffcc33); /* Gradient od pomarańczowego do jasnożółtego */
color: #fff;
border: none;
padding: 3px 5px;
border-radius: 2px;
transition: background 0.3s, color 0.3s; /* Płynna zmiana stylu */
}

/* Gradientowy tekst tylko dla linków Kontakt i Co robimy */
.nav__link--gradient {
color: transparent;
background: linear-gradient(90deg, #f39821, #ffcc33);
background-clip: text;
-webkit-background-clip: text;
transition: background 0.3s ease, color 0.3s ease;
}
.nav__link--gradient:hover {
background: linear-gradient(90deg, #ffcc33, #f39821); /* Gradient od żółtego do pomarańczowego */
background-clip: text;
-webkit-background-clip: text;
color: transparent; /* Upewnienie się, że tekst pozostaje przezroczysty */
}

.nav__link--contact {
font-family: var(--font-family-primar y);
font-size: var(--link-text-fz);
line-height: var(--link-text-lh);
font-weight: var(--link-text-fw);
letter-spacing: var(--link-text-ls);
text-transform: uppercase;
}

/* Opcjonalnie: subtelny efekt hover */
.nav__link--gradient:hover {
opacity: 0.8; /* Subtelny efekt */
}

.language-option:hover {
background-color: #e0e0e0;
}

@media (max-width: 1309px) {
.header {
  background-color: #000; /* Czarne tło dla całego nagłówka */
  color: #fff; /* Tekst na biało */
}

.header__nav {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000; /* Czarne tło menu */
  color: #fff;
  padding: 20px;
  z-index: 10;
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%); /* Menu poza ekranem */
}

.header__nav.header__nav--open {
  display: flex;
  transform: translateX(0); /* Wyświetlenie menu */
}

.header__nav a {
  color: #fff; /* Linki na biało */
  text-decoration: none;
  margin-bottom: 16px;
  font-size: 18px;
}

.header__nav a:hover {
  /* color: #ffa500; Kolor na hover */
}

.burger {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 110;
  background: none;
  border: none;
  font-size: 30px;
  color: #fff; /* Biały kolor burgera */
  cursor: pointer;
}

.burger:hover {
  color: #ffa500; /* Kolor na hover */
  }
}

</style>
